import settings from '../common/settings';

const urlArgs = window.location.search;
const clientId = $.urlParam('client_id');
const redirectUri = $.urlParam('redirect_uri');
let tfaToken = null;

function initialize() {
  if (urlArgs.indexOf('newPasswordNotification') > -1) {
    $('#newPasswordNotification').removeClass('hide');
  }

  window.onCaptchaLoaded = onCaptchaLoaded;
  window.onCaptchaSolved = onCaptchaSolved;

  loadRecaptcha();
  setupLoginForm();
  setupTfaForm();
}

function onCaptchaLoaded() {
  $('#btn-login').attr('disabled', false);
}

function onCaptchaSolved(recaptchaToken) {
  window.grecaptcha.reset();
  $('#btn-login').attr('disabled', true);

  const email = $('input[name=email]').val();
  const password = $('input[name=password]').val();
  const data = {
    email,
    password
  };

  if (clientId) {
    data.client_id = clientId;
  }

  if (redirectUri) {
    data.redirect_uri = redirectUri;
  }

  $.ajax({
    url: `${settings.url.api.login}/authenticate`,
    type: 'post',
    data,
    headers: {
      Accept: 'application/vnd.login.v1+json',
      'X-Recaptcha-Token': recaptchaToken
    },
    dataType: 'json',
    success: onSuccess,
    error: onError
  });

  function onSuccess(data) {
    if (data.has_two_factor_enabled) {
      $('#btn-login').attr('disabled', false);
      tfaToken = data.token;

      if (data.is_email_code) {
        $('#tfaMessage').hide();
        $('#tfaEmailMessage').show();
      }

      return $('form[name=loginForm]').fadeOut(200, function () {
        $('form[name=tfaForm]').fadeIn(200, function () {
          $('form[name=tfaForm] input').focus();
        });
      });
    }

    if (window.dataLayer && typeof window.dataLayer.push === 'function') {
      window.dataLayer.push({
        event: 'login',
        user_email: email
      });
    }

    window.location.href = data.redirect_uri;
  }

  function onError(err, textStatus, errorThrown) {
    if (window.dataLayer && typeof window.dataLayer.push === 'function') {
      window.dataLayer.push({
        event: 'login_error'
      });
    }

    $('#newPasswordNotification').addClass('hide');
    $('#validationFailedNotification').addClass('hide');
    $('#loginFailedNotification').addClass('hide');
    $('#forbiddenNotification').addClass('hide');
    $('#errorNotification').addClass('hide');
    $('#btn-login').attr('disabled', false);

    switch (err.status) {
      case 400:
        $('#validationFailedNotification').removeClass('hide');
        break;
      case 401:
        $('#loginFailedNotification').removeClass('hide');
        break;
      case 403:
        $('#forbiddenNotification').removeClass('hide');
        break;
      default:
        $('#errorNotification').removeClass('hide');
        Sentry.captureException(new Error(err), {
          extra: {
            url: '/authenticate',
            email: $('input[name=email]').val(),
            clientId: $.urlParam('client_id'),
            textStatus,
            errorThrown,
            responseStatus: err.status,
            responseHeaders: err.getAllResponseHeaders(),
            responseStatusCode: err.statusCode(),
            response: err.responseJSON
          }
        });
        break;
    }
  }
}

function setupLoginForm() {
  $('form[name=loginForm]').on('submit', function (e) {
    e.preventDefault();
    if (window.grecaptcha && !document.getElementById('btn-login').disabled) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute();
      });
    }
  });
}

function setupTfaForm() {
  $('#tfaMessage').show();
  $('#tfaEmailMessage').hide();

  $('form[name=tfaForm]').on('submit', function (e) {
    e.preventDefault();

    const email = $('input[name=email]').val();
    const tfaCode = $('input[name=tfaCode]').val();
    const data = {
      code: tfaCode,
      token: tfaToken
    };

    if (clientId) {
      data.client_id = clientId;
    }

    if (redirectUri) {
      data.redirect_uri = redirectUri;
    }

    $.ajax({
      url: `${settings.url.api.login}/authenticate/twoFactor`,
      type: 'post',
      data,
      headers: {
        Accept: 'application/vnd.login.v1+json'
      },
      dataType: 'json',
      success: onSuccess,
      error: onError
    });

    function onSuccess(data) {
      if (window.dataLayer && typeof window.dataLayer.push === 'function') {
        window.dataLayer.push({
          event: 'login',
          user_email: email
        });
      }

      window.location.href = data.redirect_uri;
    }

    function onError(err, textStatus, errorThrown) {
      if (window.dataLayer && typeof window.dataLayer.push === 'function') {
        window.dataLayer.push({
          event: 'login_error'
        });
      }
      $('#tfaFailedNotification').removeClass('hide');
      $('input[name=tfaCode]').val('');

      if (![400, 401, 403].includes(err.status)) {
        Sentry.captureException(new Error(err), {
          extra: {
            url: '/authenticate/twoFactor',
            email: $('input[name=email]').val(),
            clientId: $.urlParam('client_id'),
            textStatus,
            errorThrown,
            response: err
          }
        });
      }
    }
  });
}

function loadRecaptcha() {
  $('.g-recaptcha').attr('data-sitekey', settings.recaptcha.siteKey);

  const s = document.createElement('script');
  s.async = true;
  s.defer = true;
  s.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=onCaptchaLoaded');
  document.body.appendChild(s);
}

initialize();
